<template>
  <div class="thing_attrtype_list">
    <el-card>
      <el-row class="left">
        <el-col :span="24">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item>
              <el-button type="primary" icon="el-icon-plus" @click="add">新增</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-table 
        id="goodsListTable"
        :data="list" 
        style="width: 100%"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
        <el-table-column label="属性类型名称" prop="attrtype_name"></el-table-column>
        <el-table-column label="属性数量" prop="attr_count"></el-table-column>
        <el-table-column label="操作" width="250" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-button size="mini" type="success" plain @click="goList(scope.row.attrtype_id)">属性列表</el-button>
              <el-button size="mini" type="primary" plain @click="update(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" plain @click="remove(scope.row.attrtype_id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
    </el-card>

    <!-- 添加/编辑 -->
    <el-dialog :title="title" width="550px" :visible.sync="addVisible">
      <el-form ref="addRef" :model="addForm" :rules="addRules" label-width="120px" class="left">
        <el-form-item label="属性类型名称:" prop="attrtype_name">
          <el-input v-model="addForm.attrtype_name" placeholder="请输入属性类型名称"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="default" @click="addClose">取消</el-button>
        <el-button type="primary" @click="addSub">保存</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Pagination.vue'
export default {
  components:{
    Pagination
  },
  data () {
    return {
      queryInfo: {
        pagenum: 1,
        pagesize: 10,
      },
      total:10,
      list:[],

      title: '添加角色',
      addVisible: false,
      addForm: {
        attrtype_id: '',
        attrtype_name: ''
      },
      addRules: {
        attrtype_name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }],
      },
    }
  },
  created() {
    if(!this.$store.state.userInfo) {
      // 防止未登录
      this.$router.push({
        path: '/login'
      })
    } else {
      this.getList();
    }
  },
  methods: {
    getList(){
      var url = 'thing/attrtype_list';
      let params = {
        size: this.queryInfo.pagesize,
        page: this.queryInfo.pagenum,
      };
      this.fd_post(url, params).then((res) => {
        console.log(res)
        if(res.status) {
          this.total = res.total;
          this.list = res.list;
        } else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        this.$message.error('网络错误');
      });
    },
    //页数改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum=1
      this.queryInfo.pagesize=newSize
      this.getList();
    },
    //页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum=newPage
      this.getList();
    },
    //添加弹窗打开
    add() {
      this.title = '添加';
      this.addForm = {
        attrtype_id: '',
        attrtype_name: '',
      };
      this.addVisible = true;
    },
    update(item) {
      this.title = '编辑';
      this.addForm = {
        attrtype_id: item.attrtype_id,
        attrtype_name: item.attrtype_name
      };
      this.addVisible = true;
    },
    //关闭添加弹窗
    addClose() {
      this.$refs.addRef.resetFields();
      this.addForm.attrtype_name = '';
      this.addVisible = false;
    },
    //确认添加
    addSub() {
      this.$refs.addRef.validate(value => {
        if(!value) return
        var url = 'thing/addOrUpdateAttrtype';
        let params = this.addForm;
        this.fd_post(url, params).then((res) => {
          if(res.status) {
            this.addVisible = false;
            this.$refs.addRef.resetFields();
            this.$message.success('操作成功');
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        }).catch((err) => {
          this.$message.error('网络错误');
        });
      })
    },
    remove(attrtype_id) {
      this.$confirm('你确定要删除吗,此操作将永久删除该条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var url = 'thing/delete_attrtype';
        let params = {
          attrtype_id: attrtype_id
        };
        this.fd_post(url, params).then((res) => {
          if(res.status) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        }).catch((err) => {
          this.$message.error('网络错误');
        });
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },
    goList(attrtype_id) {
      this.$router.push({path:'/thing_attr',query: {attrtype_id: attrtype_id}})
    },
  },
}
</script>

<style lang="scss" scoped>
  .thing_attrtype_list{

  }
</style>